@use 'common/variables' as *;

.d-block                { display: block; }
.d-none                 { display: none; }

.di-block               { display: block !important; }
.di-none                { display: none !important; }

.d-hide {
  opacity: 0;
  pointer-events: none;
  cursor: default;
}

.d-hide-transition {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  cursor: default;
  transition: max-height .2s linear;
}

.custom-scrollbar {
  scrollbar-color: $bgTertiary transparent;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: $bgTertiary;
    &:hover {
      background-color: $bgQuaternary;
    }
  }
}

.opacity-zero           { opacity: 0; }
.opacity-full           { opacity: 1; }

.overflow-hidden        { overflow: hidden; }
.overflow-visible       { overflow: visible; }
.overflow-auto          { overflow: auto; }
.overflow-y-hidden      { overflow-y: hidden; }
.overflow-y-scroll      { overflow-y: scroll; }
.overflow-y-auto        { overflow-y: auto; }

.pointer-none           { pointer-events: none; }
.disabled {
  background-color: $bgQuaternary !important;
  pointer-events: none;
}

.error-color            { color: $error; }
.success-color          { color: $success; }
.accent-color           { color: $primary !important; } // ToDo Revisit this color

// Text
.text-to-upper          { text-transform: uppercase;}
.text-to-lower          { text-transform: lowercase;}
.text-to-capital        { text-transform: capitalize;}
.text-transform-unset   { text-transform: unset;}


// Margins & Paddings
@for $i from 0 through 4 {
  .m-#{$i}              { margin: 0rem + $i; }
  .mx-#{$i}             { margin-left: 0rem + $i; margin-right: 0rem + $i; }
  .my-#{$i}             { margin-top: 0rem + $i; margin-bottom: 0rem + $i; }
  .mt-#{$i}             { margin-top: 0rem + $i; }
  .mr-#{$i}             { margin-right: 0rem + $i; }
  .mb-#{$i}             { margin-bottom: 0rem + $i; }
  .ml-#{$i}             { margin-left: 0rem + $i; }

  .p-#{$i}              { padding: 0rem + $i; }
  .px-#{$i}             { padding-left: 0rem + $i; padding-right: 0rem + $i; }
  .py-#{$i}             { padding-top: 0rem + $i; padding-bottom: 0rem + $i; }
  .pt-#{$i}             { padding-top: 0rem + $i; }
  .pr-#{$i}             { padding-right: 0rem + $i; }
  .pb-#{$i}             { padding-bottom: 0rem + $i; }
  .pl-#{$i}             { padding-left: 0rem + $i; }
}

@for $i from 0 through 24 {
  .gap-#{$i}             { gap: 0px + $i; }
  .gapi-#{$i}            { gap: 0px + $i !important; }
}

@for $i from 0 through 4 {
  .mi-#{$i}              { margin: 0rem + $i !important; }
  .mxi-#{$i}             { margin-left: 0rem + $i; margin-right: 0rem + $i !important; }
  .myi-#{$i}             { margin-top: 0rem + $i; margin-bottom: 0rem + $i !important; }
  .mti-#{$i}             { margin-top: 0rem + $i !important; }
  .mri-#{$i}             { margin-right: 0rem + $i !important; }
  .mbi-#{$i}             { margin-bottom: 0rem + $i !important; }
  .mli-#{$i}             { margin-left: 0rem + $i !important; }

  .pi-#{$i}              { padding: 0rem + $i !important; }
  .pxi-#{$i}             { padding-left: 0rem + $i; padding-right: 0rem + $i !important; }
  .pyi-#{$i}             { padding-top: 0rem + $i; padding-bottom: 0rem + $i !important; }
  .pti-#{$i}             { padding-top: 0rem + $i !important; }
  .pri-#{$i}             { padding-right: 0rem + $i !important; }
  .pbi-#{$i}             { padding-bottom: 0rem + $i !important; }
  .pli-#{$i}             { padding-left: 0rem + $i !important; }
}

// Sizing
.height-0               { height: 0; }
.height-100             { height: 100%; }
.height-100-vh          { height: 100vh;height: -webkit-fill-available; }
.height-max-0           { max-height: 0; }
.height-max-unset       { max-height: unset }

.width-0                { width: 0; }
.width-100              { width: 100%; }
.width-max-0            { max-width: 0; }
.width-max-unset        { max-width: unset; }

// Flex layouting
.h-flexbox {
  display: flex;

  &.justify-between     { justify-content: space-between; }
  &.justify-evenly      {justify-content: space-evenly; }
  &.justify-center      { justify-content: center; }
  &.justify-start       { justify-content: flex-start; }
  &.justify-end         { justify-content: flex-end; }

  &.items-center        { align-items: center; }
  &.items-start         { align-items: flex-start; }
  &.items-end           { align-items: flex-end; }

  &.flex-wrap           { flex-wrap: wrap; }
  &.flex-nowrap         { flex-wrap: nowrap; }

  &.flex-column         { flex-direction: column; }
  &.flex-row            { flex-direction: row; }

  &.flex-grow-2         { flex-grow: 2; }
  &.flex-grow-1         { flex-grow: 1; }
  &.flex-grow-0         { flex-grow: 0; }
}

// Grid layouting
.h-grid {
  display: grid;

  &.grid-flow-column    { grid-auto-flow: column; }

  &.rows-2              { grid-template-rows: repeat(2, 1fr); }
  &.rows-3              { grid-template-rows: repeat(3, 1fr); }
  &.rows-6              { grid-template-rows: repeat(6, 1fr); }

  &.columns-2           { grid-template-columns: auto auto; }
  &.columns-3           { grid-template-columns: auto auto auto; }
}

// Mixin for mat-table columns
@mixin mat-table-columns($columns)
{
  .mat-column-
  {
    @each $colName, $props in $columns {

      $width: map-get($props, 'width');

      &#{$colName}
      {
        flex: $width;
        min-width: $width;

        @if map-has-key($props, 'color')
        {
          color: map-get($props, 'color');
        }
      }
    }
  }
}

.no-border {
  border: none !important;
}
