// Angular Material theme
@use 'styles/angular-material-theme';
@use '@angular/cdk/overlay-prebuilt.css';

// Common files
@use 'common/variables' as *;
@use 'common/helper-classes';
@use 'common/normalize';
@use 'common/base';

// Shared Files
@use "shared/date-picker";

.default-font {
  font-family: "Open Sans", sans-serif !important;
}

/*
* Typography
*/
body {
  @extend .default-font;
  font-size: 14px;
  font-weight: 400;
  color: $textSecondary;
}

.mat-body {
  color: $textPrimary !important;
}

.mat-checkbox {
  @extend .default-font;
}

.mat-header-cell {
  @extend .default-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: $textSecondary;
}

tr.mat-header-row,
tr.mat-row,
tr.mat-footer-row {
  height: 38px !important;
}

th.mat-header-cell {
  @extend .mat-header-cell !optional;
}

h1 {
  @extend .default-font;
  font-size: 28px;
  font-weight: 700;
  color: $textPrimary;
}

h2 {
  @extend .default-font;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: $textPrimary;
}

.text-link {
  @extend .default-font;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: $interactive;
  cursor: pointer;

  .mat-icon {
    margin-right: 5px;
  }
}

tr.mat-row, tr.mat-footer-row {
  @extend .default-font;
  height: 40px;
  font-size: 14px;
}

mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: $borderMedium;
}

h3 {
  @extend .default-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: $textPrimary;

  &.bold {
    font-weight: 600;
  }
}

.headline-primary {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: $textPrimary;
}

.headline-secondary {
  color: $textPrimary;
  font-size: 18px;
  font-family: Open Sans;
  font-weight: 600;
  line-height: 22px;
}

.headline-tertiary {
  @extend .default-font;
  font-size: 16px;
  font-weight: 600;
  color: $textPrimary;
  line-height: 22px;
}

.headline-quaternary {
  @extend .default-font;
  font-size: 14px;
  font-weight: 600;
  color: $textPrimary;
  line-height: 22px;
}

.text-micro-string {
  @extend .default-font;
  font-size: 10px;
  font-weight: 600;
  color: $textPrimary;
  line-height: 14px;
}

.navigation {
  @extend .default-font;
  font-size: 20px;
  font-weight: 700;
  color: $textSecondary;
}

.h5p-runner-activity-title {
  @extend .default-font;
  font-size: 20px;
  font-weight: 700;
  color: $textPrimary;
  text-align: center;
}

.h5p-runner-activity-description {
  @extend .default-font;
  text-align: initial !important;
}

.builder-element {
  @extend .default-font;
  font-size: 12px;
  font-weight: 400;
  color: $textSecondary;
  line-height: 22px;

  &.bold {
    font-weight: 600;
  }
}

.description {
  @extend .default-font;
  font-size: 12px;
  font-weight: 400;
  color: $textSecondary;

  &.bold {
    font-weight: 600;
  }
}

.text-primary {
  @extend .default-font;
  font-size: 14px;
  font-weight: 400;
  color: $textPrimary;
  line-height: 22px;

  &.bold {
    font-weight: 600;
  }

  &.error {
    color: $errorText;
  }
}

.text-secondary {
  @extend .default-font;
  font-size: 14px;
  font-weight: 400;
  color: $textSecondary;
  line-height: 22px;
}

.text-small {
  @extend .default-font;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  color: $textSecondary;
}

.text-large {
  @extend .default-font;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: $textPrimary;

  &.bold {
    font-weight: 600;
  }
}

.breadcrumb {
  @extend .default-font;
  font-size: 14px;
  font-weight: 400;
  color: $textSecondary;
}

.subtitle {
  @extend .default-font;
  font-size: 14px;
  font-weight: 600;
  color: $textSecondary;
  line-height: 22px;
}

.sidebar-item {
  @extend .default-font;
  font-size: 16px;
  font-weight: 400;
  color: $textSecondary;

  &.active {
    font-weight: 700;
  }
}

.form-item-title {
  @extend .default-font;
  font-size: 16px;
  font-weight: 600;
  color: $textPrimary;
  line-height: 22px;
}

.mat-icon.elearnio-icon {
  height: 18px;
  width: 18px;
  color: $interactive;
}

.settings-box {
  background: $whiteColor;
  border: 1px solid $borderLight;
  border-radius: 12px;
  padding: 24px;
}

.weight-700 {
  font-weight: 700;
}

.weight-600 {
  font-weight: 600;
}

//Themes

// Vendors
// Bootstrap, materialize, font-awesome or other

html,
body {
  height: 100vh;
  height: -webkit-fill-available;
}

body {
  display: flex;
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

// Drag and Drop Table
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  overflow: visible;
  box-shadow: 0 5px 5px -3px $blackColorOpacity020,
  0 8px 10px 1px $blackColorOpacity014,
  0 3px 14px 2px $blackColorOpacity012;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .mat-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}


.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px !important;
  padding: 0 !important;
}
