@use 'common/variables' as *;

::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) {

  .mat-slide-toggle-bar {
    background-color: $interactive50;
  }

  .mat-slide-toggle-thumb {
    background-color: $interactive;
  }
}
