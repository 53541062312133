@use 'common/variables' as *;

.elearnio-mat-tabs {
  mdc-tab--active
  .mdc-tab--active {
    opacity: 1 !important;
  }

  &.with-fixed-table-header {
    .mat-mdc-tab-body-wrapper {
      overflow: unset;

      .mat-mdc-tab-body {
        overflow: unset;

        .mat-mdc-tab-body-wrapper {
          overflow: unset;

          .elearnio-mat-table {
            overflow: unset;
          }

          .elearnio-table-wrapper,
          .elearnio-mat-table-wrapper {
            overflow: unset !important;
          }

          thead {
            position: sticky;
            top: 0;
            z-index: 2;
          }
        }
      }
    }
  }
}
