@use 'common/variables' as *;

.elearnio-mat-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;

  .elearnio-mat-table-wrapper { 
    & + mat-paginator {
      position: fixed;
      bottom: 0;
      z-index: 1;
      right: 0;
      left: 0;
      padding: 0 24px;
    }
  }

  mat-paginator {
    background-color: $bgPrimary;

    .mat-mdc-paginator-container {
      padding: 0;
      min-height: 44px;

      mat-form-field {
        height: 26px;
        width: 56px;

        .mat-mdc-select-arrow-wrapper {
          padding-right: 8px;
        }
      }
    }

    .mat-mdc-paginator-page-size-label,
    .mat-select-trigger,
    .mat-mdc-paginator-page-size-label {
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
    }

    .mat-mdc-paginator-page-size-label {
      margin: 0 12px;
    }

    .mat-paginator-page-size {
      align-items: center;
      padding-bottom: 4px;
    }

    .mat-mdc-paginator-page-size-select {
      .mat-mdc-text-field-wrapper {
        padding: 0;
      }
    }

    .mat-mdc-form-field-flex {
      height: 26px;
      width: 56px;
    }

    .mat-mdc-form-field-infix {
      .mat-mdc-select-value {
        display: flex;
        justify-content: center;
      }
    }

    .mat-mdc-paginator-icon {
      fill: $interactive !important;
    }
  }

  &.with-filters {
    margin-top: 8px;
  }

  &.with-fixed-header {
    overflow: unset;

    .elearnio-mat-table-wrapper {
      overflow: unset;
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }

  &.with-fixed-header-js {
    overflow: unset;
    max-height: unset;

    thead {
      position: sticky;
      z-index: 2;
    }
  }

  &-wrapper {
    overflow: auto;
    box-shadow: 0 4px 8px 0 $blackColorOpacity004;
    border-width: 1px;
    border-color: $borderLight;
  }

  table {
    width: 100%;
    box-shadow: 0 4px 8px 0 $blackColorOpacity004;
    border-width: 1px;
    border-color: $borderLight;

    .mat-mdc-table-sticky {
      background: unset;
    }

    thead {
      vertical-align: bottom;
    }

    tr.mat-mdc-header-row,
    tr.mat-mdc-row,
    tr.mat-footer-row {
      height: 42px !important;
      -webkit-font-smoothing: auto;
    }

    th {
      padding: 10px 12px !important;
      font-size: 13px;
      font-weight: 600;
      line-height: 22px;
      padding: 12px;
    }

    td {
      padding: 10px 12px !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: $textPrimary;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .mat-sort-header-content {
      text-align: left;
    }

    td.inactive {
      color: $textSecondary;
    }

    td.mat-mdc-cell {
      border-bottom-style: none;
      background-color: $bgPrimary;

      &:hover {
        .text-link-content,
        .text-link-icon {
          color: $interactive;
        }
      }
    }

    tr:hover {
      background-color: $bgSecondary;

      td.mat-mdc-cell {
        background-color: $bgSecondary;
      }
    }

    th.mat-mdc-header-cell {
      border-top: 1px solid $borderMedium;
      border-bottom: 1px solid $borderMedium;
      background-color: $bgTertiary;
      color: $textSecondary;
      white-space: nowrap;
    }

    tr {
      td.mat-mdc-cell {
        border-bottom: 1px solid $borderMedium !important;
      }
    }

    .mat-sort-header.cdk-keyboard-focused .mat-sort-header-container,
    .mat-sort-header.cdk-program-focused .mat-sort-header-container {
        border: 0 !important;
    }

    .mat-mdc-header-cell,
    .mat-mdc-cell {
      border-right: 1px solid $borderMedium;

      &:first-child {
        border-left: 1px solid $borderMedium;
      }
    }

    .mat-sort-header-content {
      text-wrap: nowrap;
    }

    .mat-mdc-icon-button {
      width: 22px;
      height: 22px;
      line-height: 22px;
      display: block;
      padding: 0;

      mat-icon {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .mat-column-action,
    .mat-column-actions,
    .mat-column-listActions,
    .mat-column-editAction,
    .mat-column-removeAction {
      color: $interactive;
      text-align: center;
      text-align: -webkit-center;

      mat-icon {
        color: $interactive;
      }
    }
  }

  mat-paginator {
    width: 100%;
    border-top: 1px solid $borderMedium;
  }
}
