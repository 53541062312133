@use 'common/variables' as *;


::ng-deep mat-form-field {
  margin-bottom: 5px;

  &.regular {
    width: 200px !important;
  }

  &.small {
    width: 120px !important;
  }

  &.full-width {
    width: 100%;
  }

  &.mat-form-field-appearance-outline {
    .mat-mdc-text-field-wrapper {
      padding: 0 8px;
    }

    &.no-padding-bottom {
      .mat-mdc-text-field-wrapper {
        margin: 0;

        .mat-mdc-form-field-infix  {
          height: 40px;
        }
      }

      .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
        display: none;
      }
    }

    .mdc-notched-outline__notch {
      max-width: 100% !important;

      .mdc-floating-label {
        left: 0;
      }
    }

    .mat-mdc-form-field-infix {
      padding: 8px 0 !important;
      min-height: 40px;
    }

    .mdc-text-field--outlined {
      background-color: $whiteColor;
      border-radius: 8px;
    }

    .mdc-notched-outline__leading {
      border-radius: 8px 0 0 8px;
      width: 8px !important;
    }

    .mdc-notched-outline__trailing {
      border-radius: 0 8px 8px 0;
    }

    input, textarea, mat-select, mat-option {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $textPrimary;
    }

    .mat-mdc-select-arrow-wrapper {
      transform: translateY(4%);
    }

    .mat-form-field-prefix, .mat-form-field-suffix {
      top: 0;

      mat-datepicker-toggle {
        .mat-icon-button {
          width: 16px !important;
        }
      }
    }
  }

  &.mat-form-field-appearance-outline.mat-focused .mdc-notched-outline,
  &.mat-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
  &.mat-form-field.mat-focused .mat-form-field-label {
    color: $interactive;
  }

  &.mat-form-field-invalid {
    color: $error;
  }

  &.mat-form-field-warning.mat-form-field-outline-warning {
    .mdc-notched-outline {
      color: $warning !important;
    }

    .mat-form-field-appearance-outline {
      .mdc-notched-outline {
        color: $warning !important;
      }
    }
  }

  mat-error {
    line-height: 14px;
  }

  &.disable {
    pointer-events: none;

    input,
    mat-label {
      color: $textSecondary80;
    }

    .mat-mdc-select-arrow {
      color: rgba(var(--palette-foreground-disabled-text), var(--palette-foreground-disabled-text-alpha, 1));
    }
  }

  &.disable-background {
    pointer-events: none;

    .mat-form-field-outline-start {
      background-color: $bgSecondaryTransparent !important;
    }
    
    .mat-form-field-outline-gap {
      background-color: $bgSecondaryTransparent !important;
    }
    
    .mat-form-field-outline-end {
      background-color: $bgSecondaryTransparent !important;
    }
  }
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $interactive;
}

.mat-pseudo-checkbox {
  color: $interactive;
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: $interactive;
}

.mat-mdc-option:not(.mat-mdc-option-multiple) .mat-pseudo-checkbox {
  display: none !important;
}
