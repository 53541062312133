@use 'common/variables' as *;

::ng-deep mat-checkbox.elearnio-checkbox {

  .mat-checkbox-ripple .mat-ripple-element,
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $interactive !important;
  }

  &.mat-checkbox-disabled.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $textSecondary80 !important;
  }

  .mdc-checkbox {
    padding: 0;
    margin: 0;

    .mdc-checkbox__background {
      top: 0;
      left: 0;
    }
  }
}
