@use "variables" as *;

@use "shared/elearnio-mat-slide-toggle";
@use "shared/elearnio-mat-form-field";
@use "shared/elearnio-mat-radio-group";
@use "shared/elearnio-mat-checkbox";
@use "shared/actions-popover";
@use "shared/elearnio-mat-table";
@use "shared/elearnio-mat-tree";
@use "shared/elearnio-mat-tabs";
@use "shared/elearnio-mat-dialog";

:root {
  --mat-headline-1-text-tracking: normal !important;
  --mat-headline-2-text-tracking: normal !important;
  --mat-headline-3-text-tracking: normal !important;
  --mat-headline-4-text-tracking: normal !important;
  --mat-headline-5-text-tracking: normal !important;
  --mat-headline-6-text-tracking: normal !important;
  --mat-body-1-text-tracking: normal !important;
  --mat-body-2-text-tracking: normal !important;
  --mat-body-3-text-tracking: normal !important;
  --mat-button-text-tracking: normal !important;
  --mat-icon-button-text-tracking: normal !important;
  --mat-flat-button-text-tracking: normal !important;
  --mat-raised-button-text-tracking: normal !important;
  --mat-stroked-button-text-tracking: normal !important;
  --mat-table-row-item-label-text-tracking: normal !important;
  --mat-table-cell-text-tracking: normal !important;
  --mat-table-header-text-tracking: normal !important;
  --mat-input-text-tracking: normal !important;
  --mat-list-item-text-tracking: normal !important;
  --mat-menu-item-text-tracking: normal !important;
  --mat-chip-text-tracking: normal !important;
  --mat-dialog-title-text-tracking: normal !important;
  --mat-snackbar-text-tracking: normal !important;
  --mat-bottom-sheet-container-text-tracking: normal !important;
  --mat-tooltip-text-tracking: normal !important;
  --mat-sidenav-header-text-tracking: normal !important;
  --mat-sidenav-item-text-tracking: normal !important;
  --mat-card-header-text-tracking: normal !important;
  --mat-expansion-panel-header-text-tracking: normal !important;
  --mat-form-field-label-text-tracking: normal !important;
  --mat-select-item-text-tracking: normal !important;
  --mat-stepper-text-tracking: normal !important;
  --mat-progress-spinner-text-tracking: normal !important;
  --mat-toolbar-title-text-tracking: normal !important;
  --mdc-outlined-button-label-text-tracking: normal !important;
  --mdc-chip-label-text-tracking: normal !important;
  --mdc-button-label-text-tracking: normal !important;
  --mdc-fab-label-text-tracking: normal !important;
  --mdc-text-field-label-text-tracking: normal !important;
  --mdc-slider-label-text-tracking: normal !important;
  --mdc-menu-item-label-text-tracking: normal !important;
  --mdc-dialog-title-text-tracking: normal !important;
  --mdc-snackbar-text-tracking: normal !important;
  --mat-menu-item-icon-color: #2F3238 !important; // $textPrimary does not work here
  --mdc-outlined-text-field-label-text-color: #6B6D72 !important; // $textSecondary does not work here
  --mdc-outlined-text-field-hover-label-text-color: #6B6D72 !important; // $textSecondary does not work here
  --mdc-outlined-text-field-hover-outline-color: #BDC1C9 !important; // $borderDark does not work here
  --mdc-outlined-text-field-outline-color: #BDC1C980 !important; // $borderMedium does not work here
  --mat-sidenav-content-background-color: #fff !important; // $whiteColor does not work here
  --mat-outlined-button-icon-spacing: 0 !important;
  --mat-outlined-button-icon-offset: 0 !important;
  --background-raised-button: 255, 162, 0; /* Default button color */
  --background-raised-button-no-rgb: 255, 162, 0; /* Default button color */
  --mat-form-field-container-height: 40px; 
}

// My overrides & some global definitions
* {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  font-size: 14px;
  overflow-x: hidden;
  overflow-y: hidden;
  color: $textPrimary;
}

.elearnio-input {
  height: 36px;
  font-size: 14px;
  font-weight: 400;
  color: $textPrimary;
}

.table-row {
  height: 40px;
  font-size: 14px;
  border-color: $bgSecondary;
  border-width: 1px;
}

.mat-elearnio-button {
  font-family: "Open Sans", sans-serif !important;
  height: 36px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 0 16px !important;

  &.font-14 {
    font-size: 14px !important;
  }

  &.no-border {
    border: none !important;
  }

  &.no-padding {
    padding: 0 !important;
  }

  &.small-icon-button {
    min-width: 36px;
  }

  &.with-icon-margin {
    mat-icon {
      margin-right: 4px;
    }
  }

  &.inactive,
  &.mat-mdc-button-disabled {
    opacity: 0.3 !important;
  }

  .mat-button-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .mat-icon {
    font-size: 16px !important;
    height: 16px !important;
    width: 16px !important;
  }

  &.mdc-button--outlined {
    border-radius: 8px !important;
  }
}

.mat-icon {
  &.size-m {
    font-size: 24px !important;
    height: 24px !important;
    width: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    line-height: 24px !important;
  }

  &.size-sm {
    font-size: 20px !important;
    height: 20px !important;
    width: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    line-height: 20px !important;
  }

  &.size-s {
    font-size: 18px !important;
    height: 18px !important;
    width: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    line-height: 18px !important;
  }
}

// Buttons
.mat-yellow {
  @extend .mat-elearnio-button;
  font-family: "Open Sans", sans-serif;
  box-shadow: none !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: $mainButtonTextColor;
  height: 36px;
  border-radius: 8px;
  padding: 0 24px;

  &.filled {
    background-color: $mainButton;
  }

  &.mat-mdc-button-disabled {
    color: $mainButtonTextColor80 !important;
    background: $mainButton30 !important;
    text-align: center;
  }

  &.slim {
    height: 24px !important;
    font-size: 14px !important;
    line-height: 24px;
  }

  &.touch {
    height: 44px !important;
    font-size: 14px !important;
    font-weight: 600;
    align-items: center;
  }
}

.mat-white {
  @extend .mat-yellow;
  font-family: "Open Sans", sans-serif;
  color: $interactive !important;
  border: 1px solid $interactive50;

  .mat-button-wrapper {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .mat-icon {
    font-size: 16px;
    height: 16px;
    width: 16px;
  }

  &.mat-mdc-button-disabled {
    color: $interactive !important;
    background: $whiteColorOpacity030 !important;
  }

  &.mdc-button--outlined {
    border-radius: 8px;
  }

  &.filled {
    background-color: $whiteColor;
  }

  &.no-border {
    border: none;
  }
}

.mdc-button--outlined:not(.mat-mdc-button-disabled) {
  border-color: $interactive50 !important;
}

.mat-darkblue {
  @extend .mat-yellow;

  &.mdc-button--outlined {
    background-color: transparent;
    padding: 0 24px;

    mat-icon {
      color: $interactive;
    }
  }

  &.mat-mdc-button-disabled {
    color: $interactive !important;
    background: transparent !important;
  }

  &.filled {
    background-color: $interactive;
    color: $whiteColor !important;

    &.mat-mdc-button-disabled {
      color: $whiteColor !important;
      background: $interactive !important;
    }

    mat-icon {
      color: $whiteColor;
    }
  }

  color: $interactive !important;
}

a {
  font-size: 14px;
  font-weight: 600;
  color: $interactive;
}

ul,
p {
  margin: 0;
}

a:focus,
button:focus,
input:focus,
textarea:focus,
select:focus,
.menu-item:focus,
.router-link:focus {
  outline: 1px solid transparent;
}

button {
  border: none;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

img {
  display: block;
}

.main-layout {
  flex: 1;
  height: calc(100% - 64px);
  width: 100vw;
  position: relative;
  z-index: 2;

  &.mobile {
    height: calc(100% - 48px);
  }

  &.full-height {
    height: 100%;
  }
}

body.is-modal .main-layout {
  overflow: visible;

  mat-drawer-container mat-drawer-content {
    overflow: visible;
  }
}

.router-link-activated {
  font-weight: 700 !important;
}

.h5p-editor-iframe {
  width: 100%;
  height: 100%;
}

.breadcrumb {
  font-size: 14px;
  font-weight: 400;
  color: $textSecondary;

  &.active {
    font-weight: 600;
  }
}

#userback_button_container.userback-button-container .userback-button {
  display: none !important;
}

// Dialogs
::ng-deep {
  .mat-form-field-subscript-wrapper {
    font-size: 12px !important;
  }

  .mat-date-range-input-start-wrapper,
  .mat-date-range-input-end-wrapper {
    input,
    span {
      margin-top: -0.0625em;
    }
  }
}

h1.mat-dialog-title {
  font-family: "Open Sans", sans-serif;
  font-size: 28px;
  font-weight: 700;
}

.mat-mdc-dialog-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: $textSecondary;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.mat-tooltip {
  font-size: 12px;
}

.mat-select-value {
  color: $textPrimary !important;
}

.author-editor {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;

  .author-editor-count {
    padding: 0 8px;
    border-radius: 8px;
    border: solid 1px $borderMedium;
  }
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-mdc-chip-action-label,
.mat-mdc-tab {
  letter-spacing: normal !important;  // Override default letter-spacing
  -webkit-font-smoothing: auto;
}
